import React, { useEffect, useState, useRef, useCallback } from "react";
import style from "../studios/studio.module.css";
import Artist from "../../../components/adminStudio/booking/Artist";
import BookingActionBar from "../../../components/adminStudio/booking/BookingActionBar";
import ASMusicProduction from "../../../components/adminStudio/appsAndMore/ASMusicProduction";
import ASMixandMaster from "../../../components/adminStudio/appsAndMore/ASMixandMaster";
import RecordingStudio from "../../../components/adminStudio/appsAndMore/RecordingStudio";
import { useLocation } from "react-router-dom";
import Appapi from "../../../services/appAndmoreApi";
import appAndmoreApi from "../../../services/appAndmoreApi";
import { partnerAccess } from "../../../config/partnerAccess";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Dance from "../../../components/adminStudio/appsAndMore/Dance";
import categoriesApi from "../../../services/categoriesApi";
import { isObjectValuesEmpty } from "../../../utils/helperFunction";

function AllStudioPageDetailsPage() {
  const [bookingPageCount, setBookingPageCount] = useState("c1");
  const [products, setProducts] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [totalResult, setTotalResult] = useState();
  const [perPage, setPerPage] = useState(7);
  const [pageCount, setPageCount] = useState(1);
  const [showBtnLoader, setShowBtnLoader] = useState(false);
  const sendFilterDataToapi = useRef({
    minPricePerHour: "",
    maxPricePerHour: "",
    city: "",
    totalRooms: "",
    active: "",
    searchText: "",
    creationTimeStamp: "",
    sortBy: "",
  });
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("/Apps&More/studio")) {
      setBookingPageCount("c1");
    } else if (pathname.includes("/Apps&More/musicproduction")) {
      setBookingPageCount("c2");
    } else if (pathname.includes("/Apps&More/mixmaster")) {
      setBookingPageCount("c3");
    } else if (pathname.includes("/Apps&More/entity")) {
      setBookingPageCount("c4");
    }
  }, [pathname, setBookingPageCount]);

  const downloadServiceData = useCallback(async (data) => {
    const response = await appAndmoreApi.downloadServiceData(data);
    return response;
  }, []);

  const downloadData = useCallback(async (data) => {
    const response = await appAndmoreApi.downloadData(data);
    return response;
  }, []);

  const { mutate: downloadService, isLoading: isDownloadingService } =
    useMutation(downloadServiceData, {
      onSuccess: () => {
        setShowBtnLoader(false);
      },
      onError: () => {
        setShowBtnLoader(false);
      },
    });

  const { mutate: downloadGeneralData, isLoading: isDownloadingGeneral } =
    useMutation(downloadData, {
      onSuccess: () => {
        setShowBtnLoader(false);
      },
      onError: () => {
        setShowBtnLoader(false);
      },
    });

  const downloadAllData = useCallback(() => {
    let tempData = { ...sendFilterDataToapi.current };

    if (bookingPageCount === "c2" || bookingPageCount === "c3") {
      const idToUse = bookingPageCount === "c2" ? "c2" : "c3";
      delete tempData.serviceType;
      tempData.type = idToUse;
      setShowBtnLoader(true);
      downloadService(tempData);
    } else {
      delete tempData.sortBy;
      delete tempData.page;
      setShowBtnLoader(true);
      downloadGeneralData(tempData);
    }
  }, [bookingPageCount, downloadService, downloadGeneralData]);

  const fetchServiceData = useCallback(async (bookingPageCount, pageCount) => {
    if (bookingPageCount === "c2" || bookingPageCount === "c3") {
      const idToUse = bookingPageCount === "c2" ? "c2" : "c3";

      if (
        Object.keys(sendFilterDataToapi.current).some(
          (key) => sendFilterDataToapi.current[key]
        )
      ) {
        sendFilterDataToapi.current.page = pageCount;
        sendFilterDataToapi.current.serviceType = idToUse;
        return await appAndmoreApi.filterServiceData(
          sendFilterDataToapi.current
        );
      } else {
        return await Appapi.getServices(perPage, idToUse, 1, pageCount);
      }
    } else if (bookingPageCount === "c1") {
   
      const active = 1;

      if (
        Object.keys(sendFilterDataToapi.current).some(
          (key) => sendFilterDataToapi.current[key]
        ) &&
        !partnerAccess
      ) {
        delete sendFilterDataToapi.current.serviceType;
        sendFilterDataToapi.current.page = pageCount;
        return await appAndmoreApi.filterData(sendFilterDataToapi.current);
      } else {
        return await Appapi.getStudios( perPage, active, pageCount );
      }
    } else {
      return await categoriesApi.getCategoriesEntityById(
        bookingPageCount,
        pageCount,
        7
      );
    }
  }, []);

  const { isLoading, isError, error, isFetching } = useQuery(
    ["services", bookingPageCount, pageCount, sendFilterDataToapi.current],
    () => fetchServiceData(bookingPageCount, pageCount),
    {
      keepPreviousData: false,
      onSuccess: (response) => {
        if (response) {
          if (bookingPageCount === "c2" || bookingPageCount === "c3") {
            setProducts(response?.services?.results);
          } else if (bookingPageCount === "c1") {
            setProducts(
              partnerAccess ? response.allBookings : response.studios
            );
          } else {
            setProducts(response?.categoriesEntity);
          }
          setTotalPage(response?.paginate?.totalPages);
          setTotalResult(response?.paginate?.totalResults);
        }
      },
      onError: () => {},
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );

  useEffect(() => {
    const tempFilter = { ...sendFilterDataToapi.current };
    if (isObjectValuesEmpty(tempFilter)) {
    }
  }, [sendFilterDataToapi.current]);

  useEffect(() => {
    if (isFetching) {
      setProducts([]);
    }
  }, [isFetching]);

  const pagetype = "apps";

  return (
    <>
      <div className={style.allStudioDetailsPage}>
        <BookingActionBar
          pagetype={pagetype}
          bookingPageCount={bookingPageCount}
          setBookingPageCount={setBookingPageCount}
          downloadAllData={downloadAllData}
          loaderText="Downloading ..."
          showBtnLoader={showBtnLoader}
        />
        {bookingPageCount === "c1" ? (
          <RecordingStudio
            sendFilterDataToapi={sendFilterDataToapi.current}
            products={products}
            setProducts={setProducts}
            totalPage={totalPage}
            setPageCount={setPageCount}
            setTotalPage={setTotalPage}
            pageCount={pageCount}
            bookingPageCount={bookingPageCount}
            totalResult={totalResult}
            perPage={perPage}
          />
        ) : bookingPageCount === "c2" ? (
          <ASMusicProduction
            products={products}
            setProducts={setProducts}
            totalPage={totalPage}
            setPageCount={setPageCount}
            setTotalPage={setTotalPage}
            pageCount={pageCount}
            bookingPageCount={bookingPageCount}
            sendFilterDataToapi={sendFilterDataToapi.current}
            totalResult={totalResult}
            perPage={perPage}
          />
        ) : bookingPageCount === "c3" ? (
          <ASMixandMaster
            products={products}
            setProducts={setProducts}
            totalPage={totalPage}
            setPageCount={setPageCount}
            setTotalPage={setTotalPage}
            pageCount={pageCount}
            bookingPageCount={bookingPageCount}
            sendFilterDataToapi={sendFilterDataToapi.current}
            totalResult={totalResult}
            perPage={perPage}
          />
        ) : (
          <Dance
            products={products}
            setProducts={setProducts}
            totalPage={totalPage}
            setPageCount={setPageCount}
            setTotalPage={setTotalPage}
            pageCount={pageCount}
            bookingPageCount={bookingPageCount}
            sendFilterDataToapi={sendFilterDataToapi.current}
            totalResult={totalResult}
            perPage={perPage}
          />
        )}
      </div>
    </>
  );
}

export default AllStudioPageDetailsPage;
