import React from "react";
import "./layout.css";
import LoaderUpdating from "../loader/LoaderUpdating";
import CustominputError from "../inputs/CustominputError";

function Switch({ status, onClick, isloading, switchDisabled, error, touched, onBlur, name, onChange }) {
  // Custom handler to call both onChange and onClick
  const handleInputChange = (e) => {
    if (onChange) {
      onChange(e); // Call onChange to handle the state change
    }

    if (onClick) {
      onClick(e); // Call onClick for additional logic
    }
  };

  return (
    <>
      <label className={switchDisabled ? "switch switchDisabled" : "switch"}>
        {isloading ? (
          <LoaderUpdating />
        ) : (
          <>
            <input
              type="checkbox"
              name={name}
              checked={status ?? false}
              onChange={handleInputChange}  // Use custom handler for both events
              onBlur={onBlur}
              disabled={switchDisabled}
              className={switchDisabled ? "switchDisabled" : ""}
            />
            <span className={switchDisabled ? "slider switchDisabled" : "slider"}></span>
          </>
        )}
      </label>
      <CustominputError error={error} touched={touched} />
    </>
  );
}

export default Switch;
