import React from "react";
import PropTypes from "prop-types";
import style from "../../pages/admin/studios/studio.module.css";

const CustomDataList = ({
  label,
  id,
  name,
  placeholder,
  options,
  value,
  onChange,
  onBlur,
  error,
  touched,
  list,
}) => {
  return (
    <>
      <div className={style.addNewStudioinputBox}>
        <label htmlFor={id}>{label}</label>
        <input
          // required
          style={{ minHeight: "4.5vh" }}
          list={list}
          id={id}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        <datalist id={list}>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </datalist>
      </div>
      {error && touched ? <p className={style.error}>{error}</p> : null}
    </>
  );
};

CustomDataList.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  touched: PropTypes.bool,
};

CustomDataList.defaultProps = {
  placeholder: "",
  error: "",
  touched: false,
};

export default CustomDataList;
