import React, { useEffect, useMemo, useState } from "react";
import { Table, Button as AntButton, Tooltip } from "antd";
import style from "../../../pages/admin/studios/studio.module.css";
import imageNotFound from "../../../assets/imagesNotFound.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DateAndSearchFilter from "../../filters/DateAndSearchFilterComponent";
import appAndmoreApi from "../../../services/appAndmoreApi";
import Switch from "../../common/Switch";
import CopyToClipboard from "../../common/CopyToClipboard ";
import PaginationNav from "../../pagination/PaginationNav";
import CustomSkeletonTable from "../../loader/CustomSkeletonTable ";
import TableActionButton from "../../common/TableActionButton";
import { handleToggleStatus } from "../../../utils/helperFunction";

const PageSize = 10; // Define PageSize as a constant

function ASMixandMaster({
  products,
  setProducts,
  setPageCount,
  pageCount,
  totalPage,
  bookingPageCount,
  setTotalPage,
  sendFilterDataToapi,
  perPage,
  onFilterChange, // Callback function for filter changes
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pid, setPid] = useState(0);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [filterData, setFilterData] = useState(sendFilterDataToapi || {}); // Copy prop to local state

  useEffect(() => {
    // Initialize filter data
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      serviceName: "",
      serviceType: "",
    }));
  }, [sendFilterDataToapi]);

  const gotoMixAndMaster = (id, mode) => {
    const selectedProduct = products.find((product) => product._id === id);
    navigate(`/service/musicProduction/edit?id=${id}`, {
      state: {
        productData: selectedProduct,
        navCount: 3,
        isEditMode: mode === "editMode",
        showMode: mode === "showMode",
        bookingPageCount: "c3",
      },
    });
  };

  const handleSwitchChange = (studioId, isActive) => {
    handleToggleStatus({
      id: studioId,
      currentStatus: isActive,
      setState: setProducts,
      apiFunction: appAndmoreApi.updateServiceStatus,
      setLoader: setShowLoader,
      ApiResponse: "updatedService",
    }).finally(() => setShowLoader(false)); // Ensure loader is turned off even on error
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "srNo",
      key: "srNo",
      render: (text, record, index) => index + 1 + (pageCount - 1) * perPage,
    },
    {
      title: "Production",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={style.studioImage}>
            <img
              src={
                record.servicePhotos ? record.servicePhotos[0] : imageNotFound
              }
              alt="Service Thumbnail" // Added alt attribute
              onError={(e) => {
                e.target.src = imageNotFound;
              }}
            />
          </div>
          &nbsp;&nbsp;
          <CopyToClipboard textToCopy={record.fullName} />
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: ["pricing", "IN", "price"],
      key: "price",
      render: (price) => `Starting from ₹${price}`,
    },
    {
      title: "No. of services",
      dataIndex: "totalPlans",
      key: "totalPlans",
      render: (totalPlans, record) => (
        <>
          {totalPlans}
          <br />
          <small>{record.state}</small>
        </>
      ),
    },
    {
      title: "Created on",
      dataIndex: "creationTimeStamp",
      key: "creationTimeStamp",
      render: (timestamp) => moment(timestamp).format("Do MMM YY, hh:mm a"),
    },
    {
      title: "Activity Status",
      dataIndex: "isActive",
      render: (isActive, record) => (
        <Switch
          status={isActive}
          isloading={pid === record._id && showLoader}
          onClick={() => {
            setPid(record._id);
            handleSwitchChange(record._id, isActive);
          }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (id) => (
        <TableActionButton
          viewOnclick={() => gotoMixAndMaster(id, "showMode")}
          editOnclick={() => gotoMixAndMaster(id, "editMode")}
        />
      ),
    },
  ];

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return products?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, products]);

  const handleFilterChange = (filterData) => {
    if (onFilterChange) {
      onFilterChange(filterData); // Call the callback function if provided
    }
  };

  return (
    <>
      <div className={style.studioTabelDiv}>
        <DateAndSearchFilter
          setProducts={setProducts}
          setTotalPage={setTotalPage}
          bookingPageCount={bookingPageCount}
          sendFilterDataToapi={filterData} // Use local state here
          searchDisable={true}
          dateDisable={true}
          onFilterChange={handleFilterChange} // Pass the callback function
        />
        <Table
          dataSource={currentTableData}
          columns={columns}
          rowKey="_id"
          pagination={false}
          locale={{
            emptyText: <CustomSkeletonTable columnCount={columns?.length} />,
          }}
        />
      </div>
      <div className={style.tabelpaginationDiv}>
        <PaginationNav
          pageCount={pageCount}
          totalPage={totalPage}
          setPageCount={setPageCount}
          bookingPageCount={bookingPageCount}
        />
      </div>
    </>
  );
}

export default ASMixandMaster;
