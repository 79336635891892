import React, {  useState } from "react";
import { Table, Switch as AntSwitch, Button } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import imageNotFound from "../../../assets/imagesNotFound.png";
import appAndmoreApi from "../../../services/appAndmoreApi";

import style from "../../../pages/admin/studios/studio.module.css";
import CopyToClipboard from "../../common/CopyToClipboard ";
import Switch from "../../common/Switch";
import DateAndSearchFilter from "../../filters/DateAndSearchFilterComponent";
import PaginationNav from "../../pagination/PaginationNav";
import CustomSkeletonTable from "../../loader/CustomSkeletonTable ";
import TableActionButton from "../../common/TableActionButton";
import { handleToggleStatus } from "../../../utils/helperFunction";

const ASMusicProduction = ({
  products,
  setProducts,
  setPageCount,
  pageCount,
  totalPage,
  bookingPageCount,
  setTotalPage,
  sendFilterDataToapi,
  perPage,
}) => {
  const [pid, setPid] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();


  const handleSwitchChange = (studioId, isActive) => {
    handleToggleStatus({
      id: studioId,
      currentStatus: isActive,
      setState: setProducts,
      apiFunction: appAndmoreApi.updateServiceStatus,
      setLoader: setShowLoader,
      ApiResponse: "updatedService"
    });
  };
  


  const gotoMusicProduction = (id,mode) => {
    const selectedProduct = products.find((product) => product._id === id);
    if (!selectedProduct) {
      console.error(`Product not found with id ${id}`);
      return;
    }
    navigate(`/service/musicProduction/edit?id=${id}`, {
      state: { productData: selectedProduct, showMode: mode === "showMode" , isEditMode : mode === "isEditMode" , navCount: 3 },
    });
  };

  const columns = [
    {
      title: "Sr.No",
      dataIndex: "srNo",
      key: "srNo",
      render: (text, record, index) => index + 1 + (pageCount - 1) * perPage,
    },
    {
      title: "Production",
      dataIndex: "fullName",
      render: (text, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className={
                record.profileUrl === ""
                  ? `${style.studioImageNotFound}`
                  : `${style.studioImage} `
              }
            >
              <img
                src={record.servicePhotos?.[0] || imageNotFound}
                alt=""
                onError={(e) => {
                  e.target.src = imageNotFound;
                }}
              />
            </div>
            &nbsp;&nbsp;
            <CopyToClipboard textToCopy={record.fullName} />
          </div>
        </>
      ),
    },
    {
      title: "Amount",
      dataIndex: "pricing",
      render: (pricing) => `Starting from ₹${pricing?.["IN"]?.price || 0}`,
    },
    {
      title: "No. of Services",
      dataIndex: "packages",
      render: (packages, record) => (
        <>
          {packages?.length}
          <br />
          <small>{record.state}</small>
        </>
      ),
    },
    {
      title: "Created on",
      dataIndex: "creationTimeStamp",
      // sorter: (a, b) =>
      //   moment(a.creationTimeStamp) - moment(b.creationTimeStamp),
      render: (creationTimeStamp) =>
        moment(creationTimeStamp).format("Do MMM YY, hh:mm a"),
    },
    {
      title: "Activity Status",
      dataIndex: "isActive",
      render: (isActive, record) => (
        <Switch
          status={isActive}
          isloading={pid === record._id && showLoader}
          onClick={() => {
            setPid(record._id);
            handleSwitchChange(record._id, isActive);
          }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (id) => (
        <TableActionButton
        viewOnclick={() => gotoMusicProduction(id,"showMode")}
        editOnclick={() => gotoMusicProduction(id,"isEditMode")}
        />
        
      ),
    },
  ];

  return (
    <>
      <div className={style.studioTabelDiv}>
        <DateAndSearchFilter
          setProducts={setProducts}
          setTotalPage={setTotalPage}
          bookingPageCount={bookingPageCount}
          sendFilterDataToapi={sendFilterDataToapi}
          searchDisable={true}
          dateDisable={true}
        />

        <Table
          columns={columns}
          dataSource={products}
                  scroll={{ x: "max-content" }}
          pagination={false}
          rowKey="_id"
          locale={{
            emptyText: <CustomSkeletonTable columnCount={columns?.length} />,
          }}
        />
      </div>
      <div className={style.tabelpaginationDiv}>
        <PaginationNav
          pageCount={pageCount}
          totalPage={totalPage}
          setPageCount={setPageCount}
          bookingPageCount={bookingPageCount}
        />
      </div>
    </>
  );
};

export default ASMusicProduction;
