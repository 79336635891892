import { toString } from "lodash";
import appAndmoreApi from "../services/appAndmoreApi";
import categoriesApi from "../services/categoriesApi";

export const clearEmptyField = (data) => {
  // This function clears empty fields from the object
  Object.keys(data).forEach((key) => {
    if (data.hasOwnProperty(key) && (data[key] === "" || data[key] === undefined)) {
      delete data[key];
    }
  });
};

export const validateEmptyField = (checkData) => {
  let hasError = false;
  const errorFields = [];

  const errorAlert = (message) => {
    // Placeholder for your error alert function
    console.error(message);
  };

  const isEmpty = (value) => {
    return (
      value === null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null &&
        Object.keys(value).length === 0)
    );
  };

  const check = (data) => {
    for (const key of Object.keys(data)) {
      const value = data[key];

      if (isEmpty(value)) {
        errorAlert(`${key} field is empty`);
        hasError = true; // Set hasError to true if an empty field is found
        errorFields.push(key); // Collect the field name with error
        return; // Exit on first empty field
      }

      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        if (check(value)) hasError = true; // Recursively check nested objects
      }

      if (Array.isArray(value)) {
        for (const item of value) {
          if (typeof item === "object" && item !== null) {
            if (check(item)) hasError = true; // Recursively check items in arrays
          }
        }
      }
    }
    return hasError;
  };

  check(checkData);
  return { hasError, errorFields };
};

export const getComparableTime = (timeString) => {
  if (typeof timeString !== "string" || !timeString.includes(":")) {
    console.error("Invalid time string format");
    return null;
  }

  try {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date(1970, 0, 1, hours, minutes);
    return date.getTime();
  } catch (error) {
    console.error("Error parsing time:", error);
    return null;
  }
};

export const deleteKey = (obj, arrOfKeys) => {
  arrOfKeys.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      delete obj[key];
    }
  });
  return obj;
};

export const compareObj = (obj1, obj2) => {
  // Check if either is null or not an object
  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== "object" ||
    typeof obj2 !== "object"
  ) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is different
  if (keys1.length !== keys2.length) return false;

  // Recursively check each key and its value
  return keys1.every((key) => {
    // Ensure the key exists in obj2 and compare the values recursively
    if (!obj2.hasOwnProperty(key)) return false;

    const val1 = obj1[key];
    const val2 = obj2[key];

    // If both values are objects, recurse; otherwise, compare the values directly
    if (typeof val1 === "object" && typeof val2 === "object") {
      return compareObj(val1, val2);
    } else {
      return val1 === val2;
    }
  });
};

export const isValidId = (id) => {
  const regex = /^[a-fA-F0-9]{24}$/; // Regex for 24-character hexadecimal string
  return regex.test(id);
};

export const handleToggleStatus = async ({
  id,
  currentStatus,
  setState,
  apiFunction,
  setLoader,
  ApiResponse,
}) => {
  try {
    if (setLoader) setLoader(true);

    const response = await apiFunction(id, currentStatus ? 0 : 1);

    if (!response || response.error) {
      throw new Error(response?.error || "API returned an error");
    }

    // Function to safely get a nested property
    const getNestedValue = (obj, path) =>
      path.split(".").reduce((acc, key) => acc?.[key], obj);

    const updatedStatus = getNestedValue(response, ApiResponse);

    if (updatedStatus === undefined) {
      throw new Error("Invalid API response structure");
    }

    setState((prevState) =>
      prevState.map((item) =>
        item._id === id ? { ...item, isActive: currentStatus ? 0 : 1 } : item
      )
    );
  } catch (error) {
    console.error("Error updating status:", error);
    throw error; // Re-throw the error for further handling
  } finally {
    if (setLoader) setLoader(false);
  }
};

export const AmountFormat = (amount) => {
  const formatter = toString(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formatter;

}
export const getValueFromSources = (
  record,
  field,
  position = null,
  message = "Not Available"
) => {
  return (
    (position !== null
      ? record?.studioInfo?.[field]?.[position] ||
        record?.serviceInfo?.[field]?.[position] ||
        record?.categoryInfo?.[field]?.[position]
      : record?.studioInfo?.[field] ||
        record?.serviceInfo?.[field] ||
        record?.categoryInfo?.[field]) || message
  );
};
export const getInputPropsForSearch = (type, handleStudioChange) => {
  return {
    placeholder: type === "studio" ? "Search Studio" : "Search Entity",
    fetchOptions: (data) => fetchUserList(data, type),
    onChange: handleStudioChange,
    style: { width: "100%", height: "100%" },
  };
};

export const fetchUserList = async (username, type) => {
  const searchApi = type === "studio" ? appAndmoreApi : categoriesApi;
  try {
    const response = await searchApi.filterData({ searchText: username });
    let res = type === "studio" ? "studios" : "categoriesEntity";
    return response[res].map((data) => ({
      label: `${data.fullName || data.name}`,
      value: data._id,
    }));
  } catch (error) {
    console.error("Error fetching user list:", error);
    return [];
  }
};

export const formatMobileNumber = (mobileNumber) => {
  if (!mobileNumber) return ""; // Handle empty or undefined values

  let strNumber = String(mobileNumber).replace(/^\+/, ""); // Remove '+' if it exists

  if (!/^\d+$/.test(strNumber)) return ""; 

  return strNumber.length > 10 ? `+${strNumber.slice(0, 2)} ${strNumber.slice(2)}` : strNumber;
};

export const isObjectValuesEmpty = (obj) => {
  return Object.values(obj).every(value => value === "");
};


