import React from 'react'
import style from "../../../pages/admin/studios/studio.module.css";
import { MdCancel, MdOutlineAddBox } from 'react-icons/md';
const ServiceCountryPricingSection = ({addMultiplePriceDiv,handleCountrySelect,selectedCountry,
    filteredCountryData,
    handelCountryPrice,
    countryPrice,
    handleCancelcountry,
    handelMultipleCountryPriceDiv,

}) => {
  return (
  <>
  {addMultiplePriceDiv.map((el, index) => (
              <div className={style.addPriceAndCountryInput}>
                <div>
                  <select
                    name="price"
                    id=""
                    onChange={(e) => handleCountrySelect(e.target.value, index)}
                    value={selectedCountry[index]}
                    style={{
                      color: selectedCountry[index] ? "black" : "#757575",
                    }}
                  >
                    {selectedCountry[index] ? (
                      <option value={selectedCountry[index]}>
                        {selectedCountry[index] == "IN"
                          ? "India(₹)"
                          : selectedCountry[index] == "USA"
                          ? "USA($)"
                          : "Japan(¥)"}
                      </option>
                    ) : (
                      <option value="" default>
                        select Country
                      </option>
                    )}

                    {filteredCountryData.map((country, index) => {
                      if (!selectedCountry.includes(country)) {
                        return (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
               

                <div key={index}>
                  <input
                    type="number"
                    placeholder="Enter Price"
                    onChange={(event) => {
                      handelCountryPrice(event.target.value, index);
                    }}
                    value={countryPrice[index]}
                  />
                </div>
                {addMultiplePriceDiv.length > 1 && (
                  <span
                    style={{ cursor: "pointer", top: "-15%", right: "-1.5%" }}
                    className={style.cancelTeamDetailUpload}
                    onClick={() => handleCancelcountry(index)}
                  >
                    <MdCancel
                      style={{ fontSize: "1.2vmax", color: "#7575759a" }}
                    />
                  </span>
                )}
              </div>
            ))}

            {addMultiplePriceDiv.length <= 2 && (
              <span
                className={style.addTeamDetailbtn}
                onClick={handelMultipleCountryPriceDiv}
              >
                <MdOutlineAddBox /> &nbsp;<div>Add new country</div>
              </span>
            )}
  </>
  )
}

export default ServiceCountryPricingSection
