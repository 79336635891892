import React, { useEffect, useState } from "react";

import style from "../../pages/admin/studios/studio.module.css";


import DragAndDropImageDiv from "../inputs/DragAndDropImageDiv";
import dayjs from "dayjs";
import MultipleSelect from "../inputs/MultipleSelect";
import Button from "../common/Button";
import StudioFooter from "../adminStudio/StudioFooter";
import Availability from "./Availability";
import CustomInput from "../inputs/CustomInput";
import CustomTextArea from "../inputs/CustomTextArea";

import CustominputError from "../inputs/CustominputError";
import CustomMultipleSelect from "../inputs/CustomMultipleSelect";

function AddNewListing({
  setshowRoomsDetails,
  isEditMode,
  setrooms,
  rooms,
  indexofrooms,
  setIndexofrooms,
  showMode,
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur,
  handleSubmit,
}) {
  const [seletedBookinType, setSeletedBookinType] = useState(
    values?.listingDetails?.[indexofrooms]?.bookingTypes || []
  );


  let currentRoomsData = rooms[indexofrooms] || "";
  let defaultData = {
    roomName: "",

    price: "",
    discount: "",
    bookingDays: [],

    generalTime: {
      startTime: "",
      endTime: "",
    },
    bookingStartTime: [],
    bookingEndTime: [],
    roomPhotos: [],
    amenities: [],
    aboutUs: "",
  };

  const defaultStartTime = dayjs("00:00", "HH:mm").format("HH:mm");
  const defaultEndTime = dayjs("23:59", "HH:mm").format("HH:mm");
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ].map((day) => ({
    day,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  }));

 useEffect(() => {
  if(isEditMode || showMode){
    if(JSON.stringify(values?.listingDetails?.[indexofrooms]?.bookingDays) == JSON.stringify(days)){
      setFieldValue(`listingDetails.[${indexofrooms}].tempBookingDays`, "allDays")
      console.log("------------------------true haiiiiiiiiii")
    }else{
      setFieldValue(`listingDetails.[${indexofrooms}].tempBookingDays`, "custom")
      console.log("------------------------false haiiiiiiii")
    }

    // updating booking type
    const bookingTypes = values?.listingDetails?.[indexofrooms]?.bookingTypes || [];
    if (bookingTypes.length > 0) {
      const updatedBookingTypes = bookingTypes.map((item) => {
        switch (item) {
          case "b1":
            return { label: "Hourly", value: "b1" };
          case "b2":
            return { label: "Session", value: "b2" };
          case "b3":
            return { label: "Event", value: "b3" };
          default:
            return { label: "Request", value: "b4" };
        }
      });
      // console.log("Updated Booking Types:$$$$$$$$$$$$$$$$$$$$$", updatedBookingTypes);
      setFieldValue(`listingDetails.[${indexofrooms}].bookingTypes`, updatedBookingTypes);
      setSeletedBookinType(updatedBookingTypes);
    }
    
  }

  
 }, [isEditMode,showMode])

  const format = "HH:mm";
  const [selectedAmenities, setSelectedAmenities] = useState(
    values?.listingDetails?.[indexofrooms]?.amenities?.map((item) => item?.name || item) || []
  );
  // const [selectedDate, setSelectedDate] = useState(
  //   currentRoomsData?.bookingDays?.map((item) => item?.name || item) || []
  // );
  const [showPopUp, setshowPopUp] = useState(false);
  const [images, setImages] = useState(
    values?.listingDetails?.[indexofrooms]?.listingPhotos || []
  );

  const ApplyDiscount = () => {
    let dis = values?.listingDetails?.[indexofrooms]?.discountPercentage;
    let price = values?.listingDetails?.[indexofrooms]?.basePrice;

    let cal = (price, dis) => {
      let discountedAmount = (price * dis) / 100;
      let calculatedBasePrice = price - discountedAmount; // Renamed to avoid conflict
      return Math.ceil(calculatedBasePrice || 0);
    };

    setFieldValue(`listingDetails.[${indexofrooms}].price`, cal(price, dis));
  };

  const handleDataUpdate = () => {
    setshowRoomsDetails(false);
  };
  let daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const handleBasePriceChange = (event) => {
    const { value } = event.target;
    if (values?.listingDetails[indexofrooms].basePrice >= value) {
      setFieldValue(`listingDetails.[${indexofrooms}].price`, value || "");
    } else {
      setFieldValue(`listingDetails.[${indexofrooms}].price`, "");
    }
  };
  const calculateDiscount = () => {
    let actualPrice = values?.listingDetails[indexofrooms].basePrice;
    let priceToOffer = values?.listingDetails[indexofrooms].price;
    let discount = (priceToOffer / actualPrice) * 100;
    discount = 100 - discount;

    setFieldValue(
      `listingDetails.[${indexofrooms}].discountPercentage`,
      Math.ceil(discount)
    );
  };

  const handleDiscountChange = (event) => {
    let { value } = event.target;
    if (value <= 100 && value >= 0) {
      value = value;
    } else {
      value = 0;
    }

    setFieldValue(
      `listingDetails.[${indexofrooms}].discountPercentage`,
      parseInt(value)
    );
  };

  const handleBookingsDay = (value) => {
    if (value === "custom") {
      setshowPopUp(true);
      setFieldValue(
        `listingDetails.[${indexofrooms}].tempBookingDays`,
        "custom"
      );
      return;
    }

   

    setFieldValue(
      `listingDetails.[${indexofrooms}].generalTime.startTime`,
      defaultStartTime
    );
    setFieldValue(
      `listingDetails.[${indexofrooms}].generalTime.endTime`,
      defaultEndTime
    );
    setFieldValue(`listingDetails.[${indexofrooms}].bookingDays`, days);
    setFieldValue(
      `listingDetails.[${indexofrooms}].tempBookingDays`,
      "allDays"
    );
  };
  useEffect(() => {
    setFieldValue(`listingDetails.[${indexofrooms}].listingPhotos`, images);
  }, [images]);

  useEffect(() => {
    setFieldValue(
      `listingDetails.[${indexofrooms}].listingId`,
      indexofrooms + 1
    );
    console.log("rooms.length", rooms.length);
  }, []);

  useEffect(() => {
    if (selectedAmenities.length > 0) {
      let temp = selectedAmenities?.map((data, index) => {
        return {
          id: String(index),
          name: data,
        };
      });
      setFieldValue(`listingDetails.[${indexofrooms}].amenities`, temp);
    }
  }, [selectedAmenities]);

  useEffect(() => {
    console.log("seletedBookinType", seletedBookinType);

    let ans = [];

    if (seletedBookinType && seletedBookinType.length > 0) {
      if (
        seletedBookinType.some(
          (val) => val?.value === "b1" || val?.value === "b2"
        )
      ) {
        // If b1 or b2 is present, include only b1 and b2 (no duplicates)
        ans = seletedBookinType.filter(
          (val) => val?.value === "b1" || val?.value === "b2"
        );
        ans = Array.from(new Set(ans.map((val) => val?.value))).map((value) =>
          seletedBookinType.find((val) => val?.value === value)
        ); // Removes duplicates and retains original objects
      } else if (seletedBookinType.some((val) => val?.value === "b3")) {
        // If b3 is present, include only b3
        ans = seletedBookinType.filter((val) => val?.value === "b3");
      } else if (seletedBookinType.some((val) => val?.value === "b4")) {
        // If b4 is present, include only b4
        ans = seletedBookinType.filter((val) => val?.value === "b4");
      }
    }

    // Avoid unnecessary state updates
    if (JSON.stringify(ans) !== JSON.stringify(seletedBookinType)) {
      setSeletedBookinType(ans);
    }

    setFieldValue(`listingDetails.[${indexofrooms}].bookingTypes`, ans);
  }, [seletedBookinType]);

  // useEffect(() => {
  //   console.log("room Values------->", values?.listingDetails?.[indexofrooms]);
  // }, [values]);
  useEffect(() => {
    if(isEditMode){
      if(JSON.stringify(values?.listingDetails?.[indexofrooms]?.bookingDays) == JSON.stringify(days)){
        setFieldValue(`listingDetails.[${indexofrooms}].tempBookingDays`, "allDays")
        console.log("------------------------true haiiiiiiiiii")
      }else{
        setFieldValue(`listingDetails.[${indexofrooms}].tempBookingDays`, "custom")
        console.log("------------------------false haiiiiiiii")
      }
  
      // updating booking type
      const bookingTypes = values?.listingDetails?.[indexofrooms]?.bookingTypes || [];
      if (bookingTypes.length > 0) {
        const updatedBookingTypes = bookingTypes.map((item) => {
          switch (item) {
            case "b1":
              return { label: "Hourly", value: "b1" };
            case "b2":
              return { label: "Session", value: "b2" };
            case "b3":
              return { label: "Event", value: "b3" };
            case "b4":
              return { label: "Request", value: "b4" };
            default:
              return null; // Explicitly return `null` for unmatched cases
          }
        }).filter(Boolean); // Remove `null` values from the array
      
        // console.log("Updated Booking Types: $$$$$$$$$$$$$$$$$$$$$$", updatedBookingTypes);
      
        setFieldValue(`listingDetails.[${indexofrooms}].bookingTypes`, updatedBookingTypes);
        setSeletedBookinType(updatedBookingTypes);
      }
      
    }
  
    
   }, [isEditMode])
  useEffect(() => {
    setFieldValue(`listingDetails.[${indexofrooms}].listingPhotos`, images);
  }, [images]);

  return (
    <>
      <Availability
        showPopUp={showPopUp}
        setshowPopUp={setshowPopUp}
        daysOfWeek={daysOfWeek}
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleSubmit={handleSubmit}
        indexofrooms={indexofrooms}
        handleBookingsDay={handleBookingsDay}
        isEditMode={isEditMode}
        showMode={showMode}
      />
      <div className={style.addNewStudioTitle}>Add new Listing</div>
      <div className={style.addNewRoomPage}>
        <div
          style={{
            position: showMode ? "relative" : "",
            overflow: "hidden",
          }}
        >
          {showMode ? <p className={style.showmode}></p> : ""}

          <div>
            <CustomInput
              type="text"
              id="RoomName"
              name={`listingDetails.[${indexofrooms}].listingName`}
              label="Listing Name"
              placeholder="Enter listing Name"
              value={values?.listingDetails?.[indexofrooms]?.listingName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.listingDetails?.[indexofrooms]?.listingName}
              touched={touched.listingDetails?.[indexofrooms]?.listingName}
            />
            <CustomInput
              label="Base Price"
              type="number"
              name={`listingDetails.[${indexofrooms}].basePrice`}
              id="price"
              placeholder="Enter Price Per Hour"
              value={values?.listingDetails?.[indexofrooms]?.basePrice}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.listingDetails?.[indexofrooms]?.basePrice}
              touched={touched.listingDetails?.[indexofrooms]?.basePrice}
            />
            <div
              className={style.addNewStudioinputBox}
              style={{ position: "relative" }}
            >
              <label htmlFor="Discount">Discount</label>
              <input
                name={`listingDetails.[${indexofrooms}].discountPercentage`}
                type="number"
                id="Discount"
                placeholder="Enter Discount"
                onBlur={handleBlur}
                value={
                  values?.listingDetails?.[indexofrooms]?.discountPercentage
                }
                min={0}
                max={100}
                onChange={handleDiscountChange}
                style={{ position: "relative", minHeight: "4.5vh" }}
              />
              <Button
                name="Apply Discount"
                style={{
                  height: "4vh",
                  fontSize: "0.8vmax",
                  position: "absolute",
                  bottom: "16%",
                  right: "2%",
                }}
                onClick={ApplyDiscount}
                disabled={
                  `listingDetails.[${indexofrooms}].discountPercentage` == "" ||
                  `listingDetails.[${indexofrooms}].discountPercentage` == NaN
                    ? true
                    : false
                }
                type={"button"}
              />
              <CustominputError
                error={
                  errors.listingDetails?.[indexofrooms]?.discountPercentage
                }
                touched={
                  touched.listingDetails?.[indexofrooms]?.discountPercentage
                }
              />
            </div>

            <div
              className={style.addNewStudioinputBox}
              style={{ position: "relative" }}
            >
              <label htmlFor="price">Price Per Hour</label>

              <input
                type="number"
                name={`listingDetails.[${indexofrooms}].price`}
                id="price"
                placeholder="Your Price per hour is "
                value={values?.listingDetails?.[indexofrooms]?.price}
                onChange={(event) => handleBasePriceChange(event)}
                // onKeyUp={(event) => handleDiscountUpdate(event)}
                style={{ position: "relative", minHeight: "4.5vh" }}
                onBlur={handleBlur}
                // disabled
                // readOnly
              />
              <Button
                name="Calc. Discount"
                onClick={calculateDiscount}
                style={{
                  height: "4vh",
                  fontSize: "0.8vmax",
                  position: "absolute",
                  bottom: "16%",
                  right: "2%",
                }}
                disabled={
                  `listingDetails.[${indexofrooms}].price` == "" ||
                  `listingDetails.[${indexofrooms}].price` == NaN
                    ? true
                    : false
                }
                type={"button"}
              />
              <CustominputError
                error={errors.listingDetails?.[indexofrooms]?.price}
                touched={touched.listingDetails?.[indexofrooms]?.price}
              />
            </div>

            <CustomInput
              type="number"
              id="RoomArea"
              name={`listingDetails.[${indexofrooms}].area`}
              label="Listing Area"
              placeholder="Enter Approx. Area"
              value={values?.listingDetails?.[indexofrooms]?.area}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.listingDetails?.[indexofrooms]?.area}
              touched={touched.listingDetails?.[indexofrooms]?.area}
            />

            <CustomMultipleSelect
              label={"Booking Type"}
              addOptions={false}
              // optionsList={["Hourly", "Session", "Request", "Event"]}
              // optionsList={[{label:"Hourly",value:"b1"},{label:"Session",value:"b2"},{label:"Request",value:"b3"},{label:"Event",value:"b4"}]}
              optionsList={[
                { label: "Hourly", value: "b1" },
                { label: "Session", value: "b2" },
                // { label: "Event", value: "b3" },
                // { label: "Request", value: "b4" },
              ]}
              placeholder={"Select booking type"}
              name={`listingDetails.[${indexofrooms}].bookingTypes`}
              value={ values?.listingDetails?.[indexofrooms]?.bookingTypes}
              // onChange={(selected) => setFieldValue("bookingTypes?.[0]?.title", selected)}
              selectedItems={seletedBookinType}
              setSelectedItems={setSeletedBookinType}
              // addOptions={false}
              onBlur={handleBlur}
              error={errors.listingDetails?.[indexofrooms]?.bookingTypes}
              touched={touched.listingDetails?.[indexofrooms]?.bookingTypes}
              // value={values?.bookingTypes?.[0]?.title}
            />
            {values?.listingDetails?.[indexofrooms]?.bookingTypes?.[0]?.value ===
            "b2" ||  values?.listingDetails?.[indexofrooms]?.bookingTypes?.[1]?.value ===
            "b2"  ? (
              <CustomInput
                label={"Enter No Of  Hours"}
                type={"number"}
                name={`listingDetails.[${indexofrooms}].sessionHours`}
                placeholder={"Enter No Of  Hours"}
                value={values?.listingDetails?.[indexofrooms]?.sessionHours}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.listingDetails?.[indexofrooms]?.sessionHours}
                error={errors.listingDetails?.[indexofrooms]?.sessionHours}

                // touched={touched?.listingDetails?.[indexofrooms]?.sessionHours}
              />
            ) : values?.listingDetails?.[indexofrooms]?.bookingTypes[0]
                ?.value === "b3" ? (
              <CustomInput
                label={"Enter No Of  Seats"}
                type="number"
                name={`listingDetails.[${indexofrooms}].seats`}
                placeholder="Enter No Of  Seats"
                value={values?.listingDetails?.[indexofrooms]?.seats}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.listingDetails?.[indexofrooms]?.seats}
                touched={touched.listingDetails?.[indexofrooms]?.seats}
              />
            ) : (
              ""
            )}


            <label htmlFor="Dates">Booking Days</label>
            <div
              style={{
                border: "2px solid #DDDDDD",
                borderRadius: "10px",
                height: "10vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <div key="allDays">
                <input
                  type="radio"
                  id="a"
                  value="All Days"
                  onChange={() => handleBookingsDay("allDays")}
                  name={`listingDetails.${indexofrooms}.bookingDays`}
                  checked={
                    values?.listingDetails?.[indexofrooms]?.tempBookingDays ===
                    "allDays"
                  }
                  onBlur={handleBlur}
                />
                <label htmlFor="a" onClick={() => handleBookingsDay("allDays")}>
                  Available 24/7
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name={`listingDetails.${indexofrooms}.bookingDays`}
                  checked={
                    values?.listingDetails?.[indexofrooms]?.tempBookingDays ===
                    "custom"
                  }
                  id="b"
                  value="custom"
                  onBlur={handleBlur}
                  onChange={() => handleBookingsDay("custom")}
                />
                <label htmlFor="b" onClick={() => handleBookingsDay("custom")}>
                  Set Custom Availability
                </label>
              </div>
              <CustominputError
                error={
                  errors?.listingDetails?.[indexofrooms]?.bookingDays || ""
                }
                touched={
                  touched?.listingDetails?.[indexofrooms]?.bookingDays || false
                }
              />
            </div>
          </div>
          <div
          //  style={{ overflow: "visible" }}
          >
            <DragAndDropImageDiv
              images={images}
              setImages={setImages}
              isEditMode={isEditMode}
              onBlur={handleBlur}
              showMode={showMode}
              name={`listingDetails.[${indexofrooms}].listingPhotos`}
              error={errors.listingDetails?.[indexofrooms]?.listingPhotos}
              touched={touched.listingDetails?.[indexofrooms]?.listingPhotos}
            />

            <MultipleSelect
              selectedItems={selectedAmenities}
              setSelectedItems={setSelectedAmenities}
              name={`listingDetails.[${indexofrooms}].amenities`}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.listingDetails?.[indexofrooms]?.amenities}
              touched={touched.listingDetails?.[indexofrooms]?.amenities}
            />

            <CustomTextArea
              label={"Room Details"}
              type="text"
              id="aboutUs"
              placeholder="Enter Room Details"
              name={`listingDetails.[${indexofrooms}].aboutUs`}
              value={values?.listingDetails?.[indexofrooms]?.aboutUs}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.listingDetails?.[indexofrooms]?.aboutUs}
              // error={errors.listingDetails?.[indexofrooms]?.aboutUs}
              touched={touched.listingDetails?.[indexofrooms]?.aboutUs}
            />
                    <CustomInput
                    // type={"number"}
                     name={`listingDetails.[${indexofrooms}].capacity`}
                     placeholder={"Enter Capacity"}
                     label={"Artist Capacity (Artist)"}
                     onChange={handleChange}
                     onBlur={handleBlur}
                     value={values?.listingDetails?.[indexofrooms]?.capacity}
                     error={errors.listingDetails?.[indexofrooms]?.capacity}
                     touched={
                       touched.listingDetails?.[indexofrooms]?.capacity
                     }
                    
                    />
          </div>
        </div>
      </div>
      <StudioFooter
        backOnclick={() => {
          if (showMode) {
            setshowRoomsDetails(false);
          } else {
            setshowRoomsDetails(false);
            // confirmAlret(
            //   "Please save data first, otherWise room data will be delete ",
            //   ""
            // ).then((result) => {
            //   if (result.isConfirmed) {
            //     // console.log("default data is =====>", defaultData);
            //     // console.log("room data is =====>", rooms);
            //     setshowRoomsDetails(false);

            //     // setrooms((prevRooms) => {
            //     //   const newRooms = [...prevRooms];
            //     //   newRooms[indexofrooms] = defaultData; // Reset to defaultData
            //     //   return newRooms;
            //     // });
              // }
            // });
          }
          // handleDataUpdate();
          // errorAlert("hii");
        }}
        saveOnclick={() => {
          handleDataUpdate();
        }}
        backType={"reset"}
        saveDisabled={showMode}
      />
    </>
  );
}

export default AddNewListing;
