import React from "react";
import cross from "../../../assets/cross.svg";
import style from "../../../pages/admin/studios/studio.module.css";
import { MdAddAPhoto, MdOutlineAddBox } from "react-icons/md";
import imageUploadapi from "../../../services/imageUploadapi";
import { errorAlert, successAlert } from "../../alert/Alert";

function AddMultipleTeam({
  teamDetails,
  setTeamsDetails,
  isEditMode,
  showMode = false,
}) {
  const keyToMapInShowMode = (isEditMode || showMode )? "imgUrl" :  "photo";

  const handleAddTeamDetail = () => {
    setTeamsDetails([
      ...teamDetails,
      { id: teamDetails.length + 1, photo: null, name: "", designation: "", imgUrl: null },
    ]);
  };

  const handlePhotoChange = async (event, index) => {
    try {
      const imageFile = event.target.files[0];
      if (!imageFile || imageFile.size > 10485760) {
        return errorAlert("File size should be less than 10MB", "File too large");
      }
      
      const response = await imageUploadapi.singleImgUpload(imageFile);
      if (response?.imageUrl) {
        setTeamsDetails((prev) => {
          const newTeams = [...prev];
          newTeams[index] = { ...newTeams[index], photo: response.imageUrl, imgUrl: response.imageUrl };
          return newTeams;
        });
        successAlert("Image uploaded successfully!");
      }
    } catch (error) {
      errorAlert("Error uploading image. Please try again.");
    }
  };

  const handleInputChange = (event, index, field) => {
    setTeamsDetails((prev) => {
      const newTeams = [...prev];
      newTeams[index] = { ...newTeams[index], [field]: event.target.value };
      return newTeams;
    });
  };

  const handleCancelImage = (index, type) => {
    setTeamsDetails((prev) => {
      const newTeams = [...prev];
      newTeams[index] = { ...newTeams[index], [type]: null };
      return newTeams;
    });
  };

  const handleCancelTeam = (index) => {
    if (teamDetails.length > 1) {
      setTeamsDetails(teamDetails.filter((_, i) => i !== index));
    }
  };
  console.log("teamDetails",teamDetails)

  return (
    <div className={style.addTeamDetailDiv}>
      <label htmlFor="Teams">Our Teams</label>
      <div className={style.addTeamDetailDynamicDiv}>
        {teamDetails.map((team, index) => (
          
          <div key={index} className={style.addTeamDetailMainDiv}>
            <div>
              <label htmlFor={`uploadteamPhoto-${index}`} style={{ cursor: "pointer" }}>
                {!team[keyToMapInShowMode] && <MdAddAPhoto />}
              </label>
              <input
                type="file"
                id={`uploadteamPhoto-${index}`}
                className={style.displayNone}
                disabled={showMode}
                onChange={(event) => handlePhotoChange(event, index)}
              />
              {team[keyToMapInShowMode] && (
                <div>
                  <img
                    src={
                      typeof team[keyToMapInShowMode] === "string"
                        ? team[keyToMapInShowMode]
                        : URL.createObjectURL(team[keyToMapInShowMode])
                    }
                    alt="Team"
                   className={style.addTeamDetailMainDivImg}
                  />
                  <span className={style.cancelImageUpload} onClick={() => handleCancelImage(index, keyToMapInShowMode)}>
                    {!showMode && <img src={cross} alt="delete" />}
                  </span>
                </div>
              )}
            </div>
            <div>
              <input
                type="text"
                placeholder="Name"
                value={team.name}
                disabled={showMode}
                onChange={(event) => handleInputChange(event, index, "name")}
              />
              <input
                type="text"
                placeholder="Designation"
                value={team.designation}
                disabled={showMode}
                onChange={(event) => handleInputChange(event, index, "designation")}
              />
            </div>
            {teamDetails.length > 1 && (
              <span className={style.cancelTeamDetailUpload} onClick={() => handleCancelTeam(index)}>
                {!showMode && <img src={cross} alt="delete" />}
              </span>
            )}
          </div>
        ))}
        {!showMode && (
          <span className={style.addTeamDetailbtn} onClick={handleAddTeamDetail}>
            <MdOutlineAddBox /> &nbsp;<div>Add Person</div>
          </span>
        )}
      </div>
    </div>
  );
}

export default AddMultipleTeam;
