import React, { useEffect, useRef, useState } from "react";
import { MdEdit } from "react-icons/md";
import style from "../../pages/admin/studios/studio.module.css";

import StudioFooter from "./StudioFooter";
import WebDashboard2 from "../../pages/produce/WebDashBoard2";

import { useLocation, useNavigate } from "react-router-dom";
import DragAndDropImageDiv from "../inputs/DragAndDropImageDiv";
import AddNewRoom from "./AddNewRoom";
import Button from "../common/Button";
import appAndmoreApi from "../../services/appAndmoreApi";
import Swal from "sweetalert2";
import MultipleSelect from "../inputs/MultipleSelect";
import { errorAlert, successAlert } from "../alert/Alert";

import { partnerAccess } from "../../config/partnerAccess";
import MyStudioApi from "../../services/MyStudioApi";
import { deleteKey } from "../../utils/helperFunction";
import AddMultipleRooms from "./appsAndMore/AddMultipleRooms";
import AddMultipleTeam from "./appsAndMore/AddMultipleTeam";
import CustomTextArea from "../inputs/CustomTextArea";
import CustomInput from "../inputs/CustomInput";
import CustomSelect from "../inputs/CustomSelect";
import CustomDataList from "../inputs/CustomDataList";

function AddNewStudio({ setSelectTab }) {
  const submitButtonRef = useRef(null);
  const [images, setImages] = useState([]);
  const data = useLocation();

  const [showBtnLoader, setShowBtnLoader] = useState(false);
  let loaderText = "saving ...";

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    setIsEditMode(data.state?.isEditMode);
  }, []);

  const navigate = useNavigate();
  const gotoadminpage = () => {
    navigate(-1);
  };

  console.log("the data id  ================== >", data?.state?.productData);
  const rawData = data?.state?.productData;

  const userStudioid = data?.state?.productData?._id;

  const [showMode, setShowMode] = useState(data?.state?.showMode || false);

  const [tabCount, setTabCount] = useState();

  const [teamDetails, setTeamsDetails] = useState([
    { id: 1, photo: null, name: "", designation: "" },
  ]);
  useEffect(() => {
    if (data?.state?.productData?.teamDetails?.length)
      setTeamsDetails(data?.state?.productData.teamDetails);
    console.log("datails isssssss", teamDetails);
  }, [data?.state?.productData?.teamDetails?.length]);
  const [selectedStudioAmenities, setSelectedStudioAmenities] = useState([]);

  const [rooms, setrooms] = useState([
    {
      roomId: 1,
      roomName: "",
      area: "",
      pricePerHour: "",
      discountPercentage: "",
      bookingDays: [],
      generalTime: {
        startTime: "",
        endTime: "",
      },
      // generalStartTime: "",
      // generalEndTime: "",
      availabilities: [],
      bookingStartTime: [],
      bookingEndTime: [],
      roomPhotos: [],
      basePrice: 0,

      amenities: [],
      details: [],
    },
  ]);
  const [showRoomsDetails, setshowRoomsDetails] = useState(false);
  const [indexofrooms, setIndexofrooms] = useState();

  const [studioDetails, setStudioDetails] = useState({
    aboutUs: {
      aboutUs: "",
      services: "",
      infrastructure: "",
    },
    address: "",
    amenities: [],
    area: 0,
    availabilities: [],
    city: "",
    country: "",
    clientPhotos: [],
    creationTimeStamp: "",
    featuredReviews: "",
    fullName: "",
    isActive: "",
    latitude: "",
    location: { coordinates: [], type: "" },
    longitude: "",
    mapLink: "",
    maxGuests: "",
    overallAvgRating: "",
    pincode: "",
    pricePerHour: 0,
    reviews: {},
    roomsDetails: [],
    state: "",
    studioPhotos: [],
    teamDetails: [],
    totalRooms: 0,
    _id: "",
  });

  const checkEmptyFields = (checkData) => {
    let hasError = false;
    const errorFields = [];

    const errorAlert = (message) => {
      // Placeholder for your error alert function
      console.error(message);
    };

    const isEmpty = (value) => {
      return (
        value === null ||
        value === "" ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" &&
          !Array.isArray(value) &&
          value !== null &&
          Object.keys(value).length === 0)
      );
    };

    const check = (data) => {
      for (const key of Object.keys(data)) {
        if (["photo", "imgUrl"].includes(key)) continue;
        const value = data[key];

        if (isEmpty(value)) {
          errorAlert(`${key} field is empty`);
          hasError = true; // Set hasError to true if an empty field is found
          errorFields.push(key); // Collect the field name with error
          return; // Exit on first empty field
        }

        if (
          typeof value === "object" &&
          !Array.isArray(value) &&
          value !== null
        ) {
          if (check(value)) hasError = true; // Recursively check nested objects
        }

        if (Array.isArray(value)) {
          for (const item of value) {
            if (typeof item === "object" && item !== null) {
              if (check(item)) hasError = true; // Recursively check items in arrays
            }
          }
        }
      }
      return hasError;
    };

    check(checkData);
    return { hasError, errorFields };
  };

  useEffect(() => {
    setStudioDetails((prevData) => ({
      ...prevData,
      totalRooms: rooms.length,
    }));
    // console.log(rooms.length);
  }, [rooms.length]);

  useEffect(() => {
    setStudioDetails((prevData) => ({
      ...prevData, // Copy previous data
      studioPhotos: images, // Update only the studioPhotos property
    }));
  }, [images]);

  useEffect(() => {
    setStudioDetails((prevdata) => ({
      ...prevdata, // Spread previous state
      teamDetails: teamDetails, // Update teamDetails
    }));
    console.log("teamDetails", teamDetails);
  }, [teamDetails]); // Use teamDetails as the dependency

  useEffect(() => {
    console.log("studioDetails", studioDetails);
  }, [studioDetails]);
  useEffect(() => {
    setStudioDetails((prevdata) => {
      return {
        ...prevdata,
        roomsDetails: rooms,
      };
    });
  }, [rooms]);

  useEffect(() => {
    setStudioDetails((prevdata) => {
      prevdata.amenities = selectedStudioAmenities.map((name, index) => ({
        id: index,
        name,
      }));
      return prevdata;
    });
  }, [selectedStudioAmenities.length]);

  useEffect(() => {
    if (data?.state?.productData) {
      setStudioDetails(data?.state?.productData);
      setrooms(data?.state?.productData.roomsDetails);
    }
  }, [data?.state?.productData]);

  useEffect(() => {
    if (studioDetails?.studioPhotos?.length)
      setImages(studioDetails.studioPhotos);
  }, [studioDetails?.studioPhotos?.length]);

  const studioamenitiesList = [
    "Wifi",
    "AC",
    "DJ",
    "Piano",
    "Drum",
    "Car Parking",
    "Banjo",
  ];
  const filteredAmenities = studioamenitiesList.filter(
    (o) => !selectedStudioAmenities.includes(o)
  );

  useEffect(() => {
    setSelectedStudioAmenities(
      studioDetails?.amenities?.map((item) => item?.name || item) || []
    );
  }, [studioDetails?.amenities]);

  const handleSubmitButtonClick = () => {
    let hasError = false;

    studioDetails.studioPhotos.forEach((element, index) => {
      if (typeof element === "object") {
        errorAlert("Please upload STUDIO images first!");

        hasError = true;
      }
    });

    studioDetails.roomsDetails.forEach((room, roomIndex) => {
      room.roomPhotos.forEach((element, photoIndex) => {
        if (typeof element === "object") {
          errorAlert(
            `Please upload images for room  ${room.roomName.toUpperCase()} first!`
          );
          hasError = true;
        }
      });
    });

    const correctDataTypes = (data) => {
      return {
        ...data,
        area: parseInt(data.area, 10),
        maxGuests: data.maxGuests === "" ? null : data.maxGuests,
        featuredReviews: Array.isArray(data.featuredReviews)
          ? data.featuredReviews
          : [],
        amenities: data.amenities.map((amenity) => ({
          ...amenity,
          id: amenity.id.toString(),
        })),
        roomsDetails: data.roomsDetails.map((room) => ({
          ...room,
          area: room?.area?.toString(),
          pricePerHour: parseInt(room.pricePerHour, 10),

          discountPercentage: parseInt(room.discountPercentage, 10),
        })),
      };
    };

    if (!hasError) {
      const updatedStudioDetails = {
        ...studioDetails,
        roomsDetails: studioDetails.roomsDetails.map((room) => {
          const isArrayOfStrings =
            Array.isArray(room.bookingDays) &&
            room.bookingDays.every((day) => typeof day === "string");
          return {
            ...room,
            bookingDays: isArrayOfStrings
              ? room.bookingDays.map((day, index) => ({
                  id: index,
                  name: day,
                }))
              : room.bookingDays,
          };
        }),
      };

      if (isEditMode) {
        let correctedRealData = correctDataTypes(updatedStudioDetails);
        console.log("correctedRealData----->", correctedRealData);

        const checkData = { ...correctedRealData };
        deleteKey(checkData, [
          "availabilities",
          "clientPhotos",
          "creationTimeStamp",
          "featuredReviews",
          "isActive",
          "latitude",
          "location",
          "longitude",
          "overallAvgRating",
          "_id",
          "pricePerHour",
          "reviews",
        ]);

        const result = checkEmptyFields(checkData);
        let hasError = result.hasError;
        console.log(`Has error: ${result.hasError}`);

        if (hasError == true)
          return errorAlert(`Empty fields: ${result.errorFields.join(", ")}`);
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Update it!",
        }).then((result) => {
          if (result.isConfirmed) {
            console.log("studioDetails", correctedRealData);
            let dynamicApi = null;
            if (partnerAccess) {
              dynamicApi = MyStudioApi;
            } else {
              dynamicApi = appAndmoreApi;
            }

            setShowBtnLoader(true);
            console.log(showBtnLoader);
            let deleteTeamPhoto = { ...correctedRealData };

            deleteTeamPhoto.teamDetails.map((data) => delete data.photo);
            correctedRealData = { ...deleteTeamPhoto };

            dynamicApi
              .updateStudio(userStudioid, correctedRealData)
              .then((response) => {
                console.log("Studio updated:", response);
                if (response) {
                  if (response.status) {
                    successAlert("Studio Updated!", "Your data has been saved.");

                    setShowBtnLoader(false);

                    navigate(-1);
                    if (!response.status) {
                      errorAlert(response.message);
                    }
                  } else {
                    errorAlert(response.message);

                    setShowBtnLoader(false);
                  }
                }
              })
              .catch((error) => {
                console.error("Error updating studio:", error);
                errorAlert(error.message);
                setShowBtnLoader(false);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  showConfirmButton: false,
                  timer: 1800,
                });
              });
            console.log("studioDetails", correctedRealData);
          }
        });
      } else {
        let correctedRealData = correctDataTypes(updatedStudioDetails);
        console.log("correctedRealData----->", correctedRealData);
        const checkData = { ...correctedRealData };
        deleteKey(checkData, [
          "availabilities",
          "clientPhotos",
          "creationTimeStamp",
          "featuredReviews",
          "isActive",
          "latitude",
          "location",
          "longitude",
          "overallAvgRating",
          "_id",
          "pricePerHour",
          "reviews",
        ]);

        let result = checkEmptyFields(checkData);
        let hasError = result.hasError;
        console.log(`Has error: ${result.hasError}`);

        if (hasError == true)
          return errorAlert(`Empty fields: ${result.errorFields.join(", ")}`);
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Create it!",
        }).then((result) => {
          if (result.isConfirmed) {
            setShowBtnLoader(true);
            let deleteTeamPhoto = { ...correctedRealData };

            deleteTeamPhoto.teamDetails.map((data) => delete data.photo);
            correctedRealData = { ...deleteTeamPhoto };

            appAndmoreApi
              .createStudio(correctedRealData)
              .then((response) => {
                console.log("Studio created:", response);
                if (response) {
                  if (response.status) {
                    setShowBtnLoader(false);
                    Swal.fire({
                      title: "Studio Created!",
                      text: "Your data has been saved.",
                      icon: "success",
                      showConfirmButton: false,
                      timer: 1800,
                    });
                    navigate(-1);
                  } else {
                    setShowBtnLoader(false);
                    errorAlert(response.message);
                  }
                }
              })
              .catch((error) => {
                console.error("Error creating studio:", error);
                setShowBtnLoader(false);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  showConfirmButton: false,
                  timer: 1800,
                });
              });
            console.log("correctedRealData", correctedRealData);
          }
        });
      }
    }
  };
  const changeMode = () => {
    setIsEditMode(true);
    setShowMode(false);

    successAlert("edit mode on");
  };

  return (
    <>
      <div className={style.wrapper}>
        <WebDashboard2
          navCount={partnerAccess ? 2 : 5}
          tabCount={tabCount}
          setTabCount={setTabCount}
        />
        <div className={style.studioMainScreen}>
          {showRoomsDetails ? (
            <AddNewRoom
              setshowRoomsDetails={setshowRoomsDetails}
              isEditMode={isEditMode}
              rooms={rooms}
              setrooms={setrooms}
              setIndexofrooms={setIndexofrooms}
              indexofrooms={indexofrooms}
              showMode={showMode}
              rawData={rawData}
            />
          ) : (
            <>
              <div className={style.addNewStudioTitle}>
                {isEditMode && showMode
                  ? "Studio details"
                  : isEditMode
                  ? "Edit Studio"
                  : "Add new studio"}

                {showMode && (
                  <Button
                    name={" Edit"}
                    icon={<MdEdit />}
                    style={{ height: "50%", fontSize: "0.8vmax", gap: "5%" }}
                    onClick={changeMode}
                  />
                )}
              </div>
              <form className={style.addNewStudioPage}>
                <div
                  style={{
                    position: showMode ? "relative" : "",
                    minHeight: "100%" ,
                    height: "auto",
                    overflowY: "hidden",
                  }}
                >
                  {showMode ? <p className={style.showmode}></p> : ""}

                  <div>
                    <CustomInput
                      label={"Studio Name"}
                      htmlFor={"studioName"}
                      required
                      type="text"
                      id="studioName"
                      placeholder="Enter Studio Name"
                      name="studioName"
                      value={studioDetails?.fullName}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          fullName: e.target.value,
                        })
                      }
                    />

                    <CustomInput
                      label="Total Area"
                      htmlFor="area"
                      required
                      type="number"
                      id="area"
                      placeholder="Enter Approx. Area"
                      name="area"
                      value={studioDetails?.area}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          area: e.target.value,
                        })
                      }
                    />

                    <CustomInput
                      label="Studio Pincode"
                      htmlFor="pincode"
                      required
                      type="number"
                      id="pincode"
                      name="pincode"
                      placeholder="Enter Pincode"
                      value={studioDetails?.pincode}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          pincode: e.target.value,
                        })
                      }
                    />

                    <CustomDataList
                      label="Studio City"
                      list="city"
                      id="addcity"
                      placeholder="Select city Name"
                      name="city"
                      value={studioDetails?.city}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          city: e.target.value,
                        })
                      }
                      options={["Mumbai", "Delhi", "Bombay"]}
                    />

                    <MultipleSelect
                      selectedItems={selectedStudioAmenities}
                      setSelectedItems={setSelectedStudioAmenities}
                    />
                    <CustomInput
                      label="Studio MapLink"
                      htmlFor="studioName"
                      required
                      type="text"
                      id="studioName"
                      placeholder="Enter Studio MapLink"
                      name="studioName"
                      value={studioDetails?.mapLink}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          mapLink: e.target.value,
                        })
                      }
                    />

                    <CustomTextArea
                      label={"About Studio"}
                      htmlFor={"aboutStudio"}
                      type={"text"}
                      name="aboutStudio"
                      id="aboutStudio"
                      placeholder="Enter Studio Details"
                      value={studioDetails?.aboutUs?.aboutUs}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          aboutUs: {
                            ...studioDetails?.aboutUs,
                            aboutUs: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div>
                    <DragAndDropImageDiv
                      images={images}
                      setImages={setImages}
                      isEditMode={isEditMode}
                      showMode={showMode}
                    />

                    <CustomSelect
                      label="Studio Capacity (Artist)"
                      id="guest"
                      name="maxGuests"
                      value={studioDetails?.maxGuests}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          maxGuests: e.target.value,
                        })
                      }
                      options={{
                        1: "1",
                        2: "2",
                        3: "3",
                        4: "4",
                        5: "5",
                        6: "6",
                        7: "7",
                        8: "8",
                        9: "9",
                        10: "10",
                      }}
                      defaultOption="Select Maximum Artist allowed"
                    />

                    <CustomDataList
                      label="Select State"
                      list="state"
                      id="addstate"
                      placeholder="Select state Name"
                      name="state"
                      value={studioDetails?.state}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          state: e.target.value,
                        })
                      }
                      options={["Maharashtra", "Delhi", "Gujarat"]}
                    />

                    <CustomSelect
                      label="Select Country"
                      list="country"
                      id="country"
                      placeholder="Select Country"
                      name="country"
                      value={studioDetails?.country}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          country: e.target.value,
                        })
                      }
                      options={{ India: "IN", Japan: "JP", USA: "US" }}
                      defaultOption="Select Country"
                    />

                    <CustomInput
                      label="Studio Address"
                      htmlFor="address"
                      required
                      type="text"
                      id="address"
                      placeholder="Enter Studio Area"
                      name="address"
                      value={studioDetails?.address}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          address: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div style={{ position: showMode ? "relative" : "" }}>
                  {showMode ? <p className={style.showmode}></p> : ""}

                  <div>
                    <CustomTextArea
                      htmlFor={"studioService"}
                      label={"Studio Services"}
                      name={"studioService"}
                      value={studioDetails?.aboutUs?.services}
                      placeholder={"Enter Studio Services"}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          aboutUs: {
                            ...studioDetails?.aboutUs,
                            services: e.target.value,
                          },
                        })
                      }
                    />
                    <br />

                    <CustomTextArea
                      htmlFor={"infrastructure"}
                      label={"Infrastructure"}
                      name={"infrastructure"}
                      value={studioDetails?.aboutUs?.infrastructure}
                      placeholder={"Enter Approx. Area"}
                      onChange={(e) =>
                        setStudioDetails({
                          ...studioDetails,
                          aboutUs: {
                            ...studioDetails?.aboutUs,
                            infrastructure: e.target.value,
                          },
                        })
                      }
                    />

                    <br />

                    <div className={style.roomAndClassSection}>
                      <div>
                        <AddMultipleRooms
                          rooms={rooms}
                          setrooms={setrooms}
                          setshowRoomsDetails={setshowRoomsDetails}
                          showRoomsDetails={showRoomsDetails}
                          indexofrooms={indexofrooms}
                          isEditMode={isEditMode}
                          setIndexofrooms={setIndexofrooms}
                          showMode={showMode}
                          studioDetails={studioDetails}
                        />
                      </div>
                      <div>
                        <AddMultipleTeam
                          teamDetails={teamDetails}
                          setTeamsDetails={setTeamsDetails}
                          data={data}
                          isEditMode={isEditMode}
                          showMode={showMode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button style={{ display: "none" }} ref={submitButtonRef}>
                  submit
                </button>
                {/* <p className={style.showmode}></p> */}
              </form>
              <StudioFooter
                setSelectTab={setSelectTab}
                backOnclick={gotoadminpage}
                saveType={"submit"}
                saveOnclick={showMode ? "" : handleSubmitButtonClick}
                saveDisabled={showMode}
                loaderText={loaderText}
                showBtnLoader={showBtnLoader}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AddNewStudio;
