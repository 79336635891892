import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useReducer,
  useState,
  useCallback,
} from "react";
import { Table } from "antd";
import style from "../../../pages/admin/studios/studio.module.css";
import { AiFillFilter } from "react-icons/ai";
import Switch from "../../common/Switch";
import imageNotFound from "../../../assets/imagesNotFound.png";
import { useNavigate } from "react-router-dom";
import PriceFilter from "../../filters/PriceFilter";
import appAndmoreApi from "../../../services/appAndmoreApi";
import { AccessContext } from "../../../utils/context";
import moment from "moment";
import DateAndSearchFilter from "../../filters/DateAndSearchFilter";
import PaginationNav from "../../pagination/PaginationNav";
import { theme } from "../../../config/theme";
import { debounce } from "lodash";
import TableActionButton from "../../common/TableActionButton";
import { constantVariable } from "../../../config/constantValue";
import { handleToggleStatus } from "../../../utils/helperFunction";
import CopyToClipboard from "../../common/CopyToClipboard ";
import CustomSkeletonTable from "../../loader/CustomSkeletonTable ";

// Define initial state
const initialState = {
  selectedCity: [],
  selectedRoom: [],
  selectedStatus: [],
  priceFilter: {
    minPrice: "",
    maxPrice: "",
  },
  shortby: "creationTimeStamp:desc",
  loader: false,
  showloader: false,
  selectedStudioId: 0,
};

// Reducer function to handle state updates
function reducer(state, action) {
  switch (action.type) {
    case "SET_SELECTED_CITY":
      return { ...state, selectedCity: action.payload };
    case "SET_SELECTED_ROOM":
      return { ...state, selectedRoom: action.payload };
    case "SET_SELECTED_STATUS":
      return { ...state, selectedStatus: action.payload };
    case "SET_PRICE_FILTER":
      return { ...state, priceFilter: action.payload };
    case "SET_SHORTBY":
      return { ...state, shortby: action.payload };
    case "SET_LOADER":
      return { ...state, loader: action.payload };
    case "SET_SHOWLOADER":
      return { ...state, showloader: action.payload };
    case "SET_SELECTED_STUDIO_ID":
      return { ...state, selectedStudioId: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const RecordingStudio = ({
  products,
  setProducts,
  setPageCount,
  pageCount,
  totalPage,
  bookingPageCount,
  setTotalPage,
  filterNav,
  setfilterNav,
  sendFilterDataToapi,
  perPage,
  totalResult,
}) => {
  // Use reducer to manage state
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    selectedCity,
    selectedRoom,
    selectedStatus,
    priceFilter,
    shortby,
    loader,
    showloader,
    selectedStudioId,
  } = state;

  const initialRender = useRef(true);
  const navigate = useNavigate();
  const tableAccess = useContext(AccessContext);
  const pageSize = 10; // Adjust based on your needs
  const loadingTimeoutRef = useRef(null);
  const [showpricefilter, setshowpricefilter] = useState(false);

  // Handle switch change for studio status
  const handleSwitchChange = (studioId, isActive) => {
    dispatch({ type: "SET_SHOWLOADER", payload: true });

    handleToggleStatus({
      id: studioId,
      currentStatus: isActive,
      setState: setProducts,
      apiFunction: appAndmoreApi.updateStudioStatus,
      setLoader: (value) =>
        dispatch({ type: "SET_SHOWLOADER", payload: value }),
      ApiResponse: "status",
    }).finally(() => {
      dispatch({ type: "SET_SHOWLOADER", payload: false });
    });
  };

  // Fetch filtered data from API
  const sendFilterDatatoapi = async () => {
    setProducts([]);
    dispatch({ type: "SET_LOADER", payload: true });

    try {
      sendFilterDataToapi.page = 1;
      setPageCount(1);
      const response = await appAndmoreApi.filterData(sendFilterDataToapi);
      console.log("filter applied:", response);
      setProducts(response.studios);
      setTotalPage(response?.paginate?.totalPages);
    } catch (error) {
      console.error("Error filtering studio:", error);
    } finally {
      dispatch({ type: "SET_LOADER", payload: false }); // Ensure loader is turned off
    }
  };

  // Debounce API calls
  const debouncedSendFilterData = useMemo(
    () =>
      debounce(() => {
        sendFilterDatatoapi();
      }, 500),
    [] // No dependencies, created only once
  );

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSendFilterData.cancel();
    };
  }, [debouncedSendFilterData]);

  // Handle table changes (pagination, filters, sorting)
  const handleTableChange = (pagination, filters, sorter) => {
    console.log("filters", filters);
    sendFilterDataToapi.city = filters?.address?.[0];
    sendFilterDataToapi.totalRooms = filters?.totalRooms?.[0];
    sendFilterDataToapi.active = filters?.isActive?.[0];

    debouncedSendFilterData();
  };

  // Toggle price filter visibility
  const handelpriceFilter = () => {
    setshowpricefilter((prevState) => !prevState);
  };

  // Close all filters
  const closeAllFilter = () => {
    setshowpricefilter(false);
  };

  // Update filter data when state changes
  useEffect(() => {
    sendFilterDataToapi.city = selectedCity[0];
    sendFilterDataToapi.totalRooms = selectedRoom[0];
    sendFilterDataToapi.active =
      selectedStatus[0] === "active"
        ? 1
        : selectedStatus[0] === "inactive"
        ? "0"
        : "";

    console.log(sendFilterDataToapi);
  }, [selectedCity, selectedRoom, selectedStatus]);

  useEffect(() => {
    sendFilterDataToapi.minPricePerHour = priceFilter.minPrice;
    sendFilterDataToapi.maxPricePerHour = priceFilter.maxPrice;
  }, [priceFilter]);

  // Fetch filtered data on shortby change
  useEffect(() => {
    setProducts([]);

    if (initialRender.current) {
      initialRender.current = false;
      return; // Skip the effect on the first render
    }

    const fetchFilteredData = async () => {
      try {
        sendFilterDataToapi.sortBy = shortby;

        sendFilterDataToapi.page = 1;
        setPageCount(1);
        const response = await appAndmoreApi.filterData(sendFilterDataToapi);
        console.log("filter applied:", response);
        setProducts(response.studios);
        setTotalPage(response?.paginate?.totalPages);
      } catch (error) {
        console.error("Error filtering studio:", error);
      }
    };

    fetchFilteredData();

    return () => {
      setProducts([]);
    };
  }, [shortby]);

  // Handle sorting
  const handelShortbyClick = () => {
    const newShortby =
      shortby === "creationTimeStamp:asc"
        ? "creationTimeStamp:desc"
        : "creationTimeStamp:asc";
    dispatch({ type: "SET_SHORTBY", payload: newShortby });
  };

  // Navigate to studio details
  const gotoStudioDetails = useCallback(
    (id, mode) => {
      const selectedProduct = products.find((product) => product._id === id);
      navigate(`/studio/edit?id=${id}`, {
        state: {
          productData: selectedProduct,
          navCount: constantVariable.AppsAndMorePage,
          isEditMode: mode === "editMode",
          showMode: mode === "showMode",
        },
      });
    },
    [products, navigate] // Dependencies
  );

  // Cleanup loading timeout
  useEffect(() => {
    return () => {
      clearTimeout(loadingTimeoutRef.current);
    };
  }, []);

  // Get serial number for table rows
  const getSerialNumber = (index, pageCount, perPage, totalResult) => {
    return shortby === "creationTimeStamp:desc"
      ? index + 1 + (pageCount - 1) * perPage
      : totalResult - pageCount * perPage + perPage - index;
  };

  // Define table columns
  const columns = useMemo(
    () => [
      {
        title: "Sr.No",
        dataIndex: "srNo",
        key: "srNo",
        render: (text, record, index) => {
          return getSerialNumber(index, pageCount, perPage, totalResult);
        },
      },
      {
        title: "Studio",
        dataIndex: "fullName",
        key: "fullName",

        render: (text, record) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={style.studioImage}>
              {record.studioPhotos ? (
                <img
                  src={record.studioPhotos[0]}
                  alt=""
                  onError={(e) => {
                    e.target.src = imageNotFound;
                  }}
                />
              ) : (
                <img src={imageNotFound} alt="" />
              )}
            </div>
            &nbsp;&nbsp;
            <CopyToClipboard textToCopy={text || "--"} />
          </div>
        ),
      },
      {
        title: "Price",
        dataIndex: ["roomsDetails", "0", "pricePerHour"],
        key: "pricePerHour",
        render: (price) => (
          <span>
            ₹{price || "N/A"} <br />
            <small>per hour</small>
          </span>
        ),
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          // <div style={{ background: "#fff", zIndex: 9999 }}>

          <PriceFilter
            closeAllFilter={closeAllFilter}
            setPageCount={setPageCount}
            priceFilter={priceFilter}
            setPriceFilter={(value) =>
              dispatch({ type: "SET_PRICE_FILTER", payload: value })
            }
            sendFilterDataToapi={sendFilterDataToapi}
            setProducts={setProducts}
            setTotalPage={setTotalPage}
            bookingPageCount={bookingPageCount}
            setfilterNav={setfilterNav}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        ),
        filterIcon: (filtered) => (
          <AiFillFilter
            style={{
              color:
                priceFilter.minPrice || priceFilter.maxPrice !== ""
                  ? "#1677FF"
                  : "#B1B1B1",
            }}
          />
        ),
        onFilter: (value, record) =>
          record.roomsDetails?.[0]?.pricePerHour
            ? record.roomsDetails[0].pricePerHour >= value
            : false,
      },

      {
        title: "Location",
        dataIndex: "address",
        key: "address",
        render: (address, record) => (
          <>
            <CopyToClipboard textToCopy={address} textLength={30} />
            <br />
            <small>
              <CopyToClipboard textToCopy={record.state} />
            </small>
          </>
        ),
        filters: [
          { text: "Mumbai", value: "Mumbai" },
          { text: "Navi Mumbai", value: "Navi Mumbai" },
          { text: "Kolkata", value: "Kolkata" },
          { text: "Pune", value: "Pune" },
          { text: "Nagpur", value: "Nagpur" },
          { text: "Surat", value: "Surat" },
          { text: "Bangalore", value: "Bangalore" },
          { text: "Hyderabad", value: "Hyderabad" },
          { text: "Chennai", value: "Chennai" },
          { text: "Delhi", value: "Delhi" },
        ],
        filterMultiple: false,
      },
      {
        title: "No. of Rooms",
        dataIndex: "totalRooms",
        key: "totalRooms",
        filters: [
          { text: "1", value: "1" },
          { text: "2", value: "2" },
          { text: "3", value: "3" },
          { text: "4", value: "4" },
          { text: "5", value: "5" },
        ],
        filterMultiple: false,
      },
      {
        title: "Created on",
        dataIndex: "creationTimeStamp",
        key: "creationTimeStamp",
        render: (timestamp) => moment(timestamp).format("Do MMM YY, hh:mm a"),
        sorter: () => handelShortbyClick(),
      },
      {
        title: "Activity Status",
        dataIndex: "isActive",
        key: "isActive",
        render: (isActive, record) => (
          <Switch
            status={record.isActive}
            isloading={selectedStudioId === record._id && showloader}
            onClick={() => {
              dispatch({ type: "SET_SELECTED_STUDIO_ID", payload: record._id });
              handleSwitchChange(record._id, record.isActive);
            }}
            disabled={tableAccess?.["app&more"]?.action === "read"}
          />
        ),
        filters: [
          { text: "active", value: 1 },
          { text: "inactive", value: "0" },
        ],
        filterMultiple: false,
      },
      {
        title: " ",
        dataIndex: "",
        key: "",
        render: (_, record) => (
          <TableActionButton
            viewOnclick={() => gotoStudioDetails(record._id, "showMode")}
            editOnclick={() => gotoStudioDetails(record._id, "editMode")}
          />
        ),
      },
    ],
    [
      shortby,
      pageCount,
      perPage,
      totalResult,
      priceFilter,
      showpricefilter,
      selectedStudioId,
      showloader,
      tableAccess,
      gotoStudioDetails,
    ]
  );

  return (
    <>
      <div className={style.studioTabelDiv}>
        <DateAndSearchFilter
          setProducts={setProducts}
          setPageCount={setPageCount}
          setTotalPage={setTotalPage}
          bookingPageCount={bookingPageCount}
          filterNav={filterNav}
          setfilterNav={setfilterNav}
          sendFilterDataToapi={sendFilterDataToapi}
          setSelectedCity={(value) =>
            dispatch({ type: "SET_SELECTED_CITY", payload: value })
          }
          setSelectedRoom={(value) =>
            dispatch({ type: "SET_SELECTED_ROOM", payload: value })
          }
          setSelectedStatus={(value) =>
            dispatch({ type: "SET_SELECTED_STATUS", payload: value })
          }
          setPriceFilter={(value) =>
            dispatch({ type: "SET_PRICE_FILTER", payload: value })
          }
          setShortby={(value) =>
            dispatch({ type: "SET_SHORTBY", payload: value })
          }
        />
        <div>
          <Table
            style={{ overflowX: "visible" }}
            columns={columns}
            dataSource={products}
            scroll={{ x: "max-content" }}
            rowKey="_id"
            pagination={false} // Disable Ant Design's default pagination
            onChange={handleTableChange}
            locale={{
              emptyText: <CustomSkeletonTable columnCount={columns?.length} />,
            }}
          />
        </div>
      </div>
      <div className={style.tabelpaginationDiv}>
        <PaginationNav
          pageCount={pageCount}
          totalPage={totalPage}
          setPageCount={setPageCount}
          bookingPageCount={pageSize} // Page size or items per page
        />
      </div>
    </>
  );
};

export default RecordingStudio;
