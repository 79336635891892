export  const constantVariable ={
    active: 1,
    inactive: 0,
    perPagelimit: 7,
    DashBoardPage: 1,
    userPage: 2,
    TeamsPage: 3,
    CategoriesPage: 4,
    AppsAndMorePage: 5,
    BookingsPage: 6,
    // TransactionsPage:7,
    PromotionsPage: 7,
    recordingStudio : "c1",
    musicProduction : "c2",
    mixMaster : "c3",
    entity:"c4",
    gstNo:"27AAJCC8000E1ZZ",
    "t&c": "https://choira.io/#/Termsandcondition",
    disclaimer: "https://choira.io/#/Disclaimer",
    }