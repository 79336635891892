// theme.js
const theme = {
    primary: '#FFC701',
    primaryLight: '#ffda55',
    primaryDark: '#fcbc0d',
    secondary: '#6c757d',
    success: '#28a745',
    danger: 'red',
    light: '#f8f9fa',
    dark: '#343a40',
    statusActive:"#FFF3CA",
    statusPending:"#9c9d9d73",
    statusCancelled:"#FFDDDD",
    statusCompleted:"#DDFFF3",

  };
  
  // Function to inject CSS variables into the :root
  const applyTheme = (theme) => {
    const root = document.documentElement; // Target the :root selector
    Object.keys(theme).forEach((key) => {
      root.style.setProperty(`--${key}`, theme[key]);
    });
  };
  
  export { theme, applyTheme };
  