import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { Tooltip } from "antd";
import { GoEye } from "react-icons/go";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { theme } from "../../config/theme";

const TableActionButton = ({
  viewOnclick,
  editOnclick,
  deleteOnClick,
  hideView,
  hideEdit,
  hideDelete,
}) => {
  const iconStyles = {
    cursor: "pointer",
    margin: "0 10px",
  };

  const deleteIconStyles = {
    ...iconStyles,
    color: theme.danger,
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {/* View Button */}
      {!hideView && (
        <Tooltip title="View">
          <GoEye style={iconStyles} onClick={viewOnclick} />
        </Tooltip>
      )}

      {/* Edit Button */}
      {!hideEdit && (
        <Tooltip title="Edit">
          <MdEdit style={{ ...iconStyles, color: theme.primary }} onClick={editOnclick} />
        </Tooltip>
      )}

      {/* Delete Button */}
      {!hideDelete && (
        <Tooltip title="Delete">
          <RiDeleteBin5Fill style={deleteIconStyles} onClick={deleteOnClick} />
        </Tooltip>
      )}
    </div>
  );
};

// Define PropTypes
TableActionButton.propTypes = {
  viewOnclick: PropTypes.func,
  editOnclick: PropTypes.func, 
  deleteOnClick: PropTypes.func,
  hideView: PropTypes.bool, 
  hideEdit: PropTypes.bool,
  hideDelete: PropTypes.bool, 
};

TableActionButton.defaultProps = {
  viewOnclick: () => {},
  editOnclick: () => {},
  deleteOnClick: () => {},
  hideView: false,
  hideEdit: false,
  hideDelete: false,
};

export default TableActionButton;