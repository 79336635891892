import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
} from "react";
import Button from "../../common/Button";
import { LuFilePlus } from "react-icons/lu";
import style from "../../../pages/admin/studios/studio.module.css";
import { FaDownload } from "react-icons/fa";
import { MdNoteAdd } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import appAndmoreApi from "../../../services/appAndmoreApi";
import { useNavigateRouter } from "../../../navigateRoute";
import { AccessContext } from "../../../utils/context";
import dynamicNav from "../../../utils/dynamicNav";
import categoriesApi from "../../../services/categoriesApi";
import { constantVariable } from "../../../config/constantValue";

// Custom Hook for fetching and setting categories
const useCategories = (
  pageData,
  setBookingOptions,
  topnav,
  setBookingPageCount,
  goToPage
) => {
  useEffect(() => {
    const fetchAndSetCategories = async () => {
      if (pageData === "Apps&More") {
        try {
          const response = await categoriesApi.getAllCategories();
          // console.log("response----", response.categories);

          if (response?.categories?.length) {
            const data = response.categories
              .map((item, index) => ({
                id: item.id,
                label: item.categoryName,
                style: { borderLeft: index === 0 ? "none" : "" },
                onClick: () => {
                  if (index !== 0) {
                    setBookingPageCount(item.id);
                    goToPage(item.categoryName);
                  }
                },
              }))
              .sort((a, b) => a.id.localeCompare(b.id)); // Sort the array

            setBookingOptions(data); // Update state with the new array
          }
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      } else if (pageData === "Bookings") {
        setBookingOptions([...topnav]); // Create a new array and update state
      }
    };

    fetchAndSetCategories();
  }, [pageData]); // Add pageData as a dependency
};

// Function to create button configuration
const createButtonConfig = (navAccess, navToMap, buttons) => {
  if (!navAccess || !navAccess[navToMap] || !navAccess[navToMap].button) {
    return buttons.map((button) => ({
      ...button,
      // disabled: dis,
    }));
  }

  const enabledButtons = navAccess[navToMap].button.map((button) =>
    button.toLowerCase().replace(/ /g, "")
  );
  const disabledButtons = navAccess[navToMap].disabledButton.map((button) =>
    button.toLowerCase().replace(/ /g, "")
  );

  return buttons.map((button) => {
    const buttonNameLower = button.name.toLowerCase().replace(/ /g, "");
    const isDisabled = disabledButtons.includes(buttonNameLower);

    return {
      ...button,
      disabled: isDisabled,
    };
  });
};

// Reusable Button Component
const ActionButton = memo(
  ({ name, icon, style, onClick, disabled, loaderText, showBtnLoader }) => {
    return (
      <Button
        name={name}
        style={style}
        onClick={onClick}
        disabled={disabled}
        icon={icon}
        loaderText={loaderText}
        showBtnLoader={showBtnLoader}
      />
    );
  }
);
const { recordingStudio, entity, musicProduction, mixMaster } =
  constantVariable;

function BookingActionBar({
  setBookingPageCount,
  bookingPageCount,
  pagetype,
  downloadAllData,
  loaderText,
  showBtnLoader,
  setShowBtnLoader,
  sendFilterDataToapi,
}) {
  const navigate = useNavigate();
  const router = useNavigateRouter();

  let { navOption: pageData, page: type } = useParams();

  const downloadBookingsData = () => {
    if (pageData !== "Bookings") return;

    let typeMapping = {
      studio: `${recordingStudio},${entity}`,
      musicproduction: musicProduction,
      mixmaster: mixMaster,
    };

    if (!typeMapping[type]) return;
    let dataToSend = {
      type: typeMapping[type],
      ...sendFilterDataToapi,
    };

    setShowBtnLoader(true);
    appAndmoreApi
      .downloadBookingServiceData(dataToSend)
      .then((response) => console.log("data download", response))
      .catch((error) => console.error("Error downloading data:", error))
      .finally(() => setShowBtnLoader(false));
  };

  const gotoAddNew = useCallback(
    (bookingPageCount) => {
      if (bookingPageCount === recordingStudio) {
        navigate("/studio/add", {
          state: { navCount: 5, bookingPageCount: bookingPageCount },
        });
      } else if (
        bookingPageCount === musicProduction ||
        bookingPageCount === mixMaster
      ) {
        navigate("/service/musicProduction/add", {
          state: { navCount: 5, bookingPageCount: bookingPageCount },
        });
      } else {
        navigate("/entity/add", {
          state: { navCount: 5, bookingPageCount: bookingPageCount },
        });
      }
    },
    [navigate]
  );

  const gotoSlotBooking = () => {
    router.push(`/${dynamicNav}/Bookings/AddSlotBooking`, {
      state: { navCount: 4 },
    });
  };
  const gotoMusicProduction = () => {
    if (pageData == "Apps&More") {
      router.push(`/${dynamicNav}/Apps&More/musicproduction`);
    } else {
      router.push(`/${dynamicNav}/Bookings/musicproduction`);
    }
  };
  const gotoStudio = () => {
    if (pageData == "Apps&More") {
      router.push(`/${dynamicNav}/Apps&More/studio`);
    } else {
      router.push(`/${dynamicNav}/Bookings/studio`);
    }
  };
  const gotoMixMaster = () => {
    if (pageData == "Apps&More") {
      router.push(`/${dynamicNav}/Apps&More/mixmaster`);
    } else {
      router.push(`/${dynamicNav}/Bookings/mixmaster`);
    }
  };

  const goToPage = (name) => {
    if (pageData == "Apps&More") {
      router.push(`/${dynamicNav}/Apps&More/${name.replace(/\s+/g, "")}`);
    } else {
      router.push(`/${dynamicNav}/Bookings/${name.replace(/\s+/g, "")}`);
    }
  };

  const [bookingOptions, setBookingOptions] = useState([]);
  let topnav = [
    {
      id: recordingStudio,
      label: "Creative Space",
      onClick: () => {
        setBookingPageCount(recordingStudio);
        gotoStudio();
      },
      style: { borderLeft: "none" },
    },
    {
      id: musicProduction,
      label: "Music Production",
      onClick: () => {
        setBookingPageCount(musicProduction);
        gotoMusicProduction();
      },
      style: {},
    },
    {
      id: mixMaster,
      label: "Mix-Master",
      onClick: () => {
        setBookingPageCount(mixMaster);
        gotoMixMaster();
      },
    },
  ];

  useCategories(
    pageData,
    setBookingOptions,
    topnav,
    setBookingPageCount,
    goToPage
  );

  const buttons = [
    {
      name: "Download",
      icon: <FaDownload />,
      style: {
        height: "50%",
        width: "40%",
        gap: "5%",
        // visibility: [recordingStudio,
        //   musicProduction,
        //   mixMaster,].includes(bookingPageCount) ? "visible" : "hidden",
      },
      onClick: pagetype === "apps" ? downloadAllData : downloadBookingsData,
      loaderText: loaderText,
      showBtnLoader: showBtnLoader,
      disabled: ![recordingStudio, musicProduction, mixMaster].includes(
        bookingPageCount
      ),
    },
  ];
  if (bookingPageCount === recordingStudio && pagetype !== "apps") {
    buttons.push({
      name: "Slot Booking",
      icon: <LuFilePlus />,
      style: { height: "50%", width: "60%" },
      onClick: gotoSlotBooking,
    });
  }
  if (pagetype === "apps") {
    if (
      [recordingStudio, musicProduction, mixMaster].includes(bookingPageCount)
    ) {
      buttons.push({
        name: "Add New",
        icon: <MdNoteAdd />,
        style: { height: "50%", width: "45%", gap: "5%" },
        onClick: () => gotoAddNew(bookingPageCount),
      });
    } else {
      buttons.push({
        name: "Add New",
        icon: <MdNoteAdd />,
        style: { height: "50%", width: "45%", gap: "5%" },
        onClick: () => gotoAddNew(bookingPageCount),
      });
    }
  }

  let navToMap = pageData?.toLowerCase().replace(/ /g, "");
  if (navToMap === "apps&more") {
    navToMap = "app&more";
  } else if (navToMap === "mystudio") {
    navToMap = "MyStudio";
  }

  const context = useContext(AccessContext);

  const [navAccess, setnavAccess] = useState(context || "");
  let navCount = 0;
  const { pathname } = useLocation();
  if (pathname.includes("MyStudio")) {
    navCount = 2;
  } else if (pathname.includes("Bookings/studio")) {
    navCount = 3;
  }

  const processedButtons = createButtonConfig(navAccess, navToMap, buttons);

  return (
    <>
      <div className={style.bookingStudiobtn2} style={{ marginBottom: "2%" }}>
        {!navAccess ? (
          <div>
            <div>
              {navAccess
                ? navAccess[navToMap]?.navbar.map((data, index) =>
                    bookingOptions.map(
                      (option) =>
                        data.toLowerCase().replace(/ /g, "") ===
                          option.label.toLowerCase().replace(/ /g, "") && (
                          <div
                            key={index}
                            style={{
                              ...option.style,
                              backgroundColor:
                                bookingPageCount === option.id ? "#ffc701" : "",
                              borderRight:
                                bookingOptions.length - 1 === index && "none",
                            }}
                            onClick={option.onClick}
                          >
                            {option.label}
                          </div>
                        )
                    )
                  )
                : bookingOptions.map((option, index) => (
                    <div
                      key={index}
                      style={{
                        ...option.style,
                        backgroundColor:
                          option.isActive === 0
                            ? "#ADB5BD"
                            : bookingPageCount === option.id
                            ? "#ffc701"
                            : "",

                        borderRight:
                          bookingOptions.length - 1 === index && "none",
                      }}
                      onClick={option.onClick}
                    >
                      {option.label}
                    </div>
                  ))}
            </div>
          </div>
        ) : (
          <div>{navCount == 2 ? <h2>My Studio</h2> : <h2>Bookings</h2>}</div>
        )}

        <div
          style={{
            justifyContent: bookingPageCount === recordingStudio ? "" : "end",
          }}
        >
          {processedButtons.map((option, index) => (
            <ActionButton
              key={index}
              name={option.name}
              style={{
                ...option.style,
                backgroundColor:
                  bookingPageCount === option.id ? "#ffc701" : "",
              }}
              onClick={option.onClick}
              disabled={option.disabled}
              icon={option.icon}
              loaderText={option.loaderText}
              showBtnLoader={option.showBtnLoader}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default memo(BookingActionBar);
