import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { applyTheme, theme } from '../src/config/theme';
const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
   {/* <CookiesProvider defaultSetOptions={{ path: '/' }}> */}
   <QueryClientProvider client={queryClient}>
   <BrowserRouter>
    {applyTheme(theme)}
      <App />
    </BrowserRouter>
    </QueryClientProvider>
    {/* </CookiesProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
